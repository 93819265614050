import { DashboardResult } from '@/admin/components/Analytics/types'
import { DateValue } from '@/admin/components/Analytics/util'
import { cloudURLBase } from '@helpers/links'
import { fromJSDate } from '@helpers/time'
import { AnalyticsDeviceType, HitScope } from '@models/Analytics'
import { DashboardDataFlags } from '@shared/types/Analytics'
import {
  BuyingOptionSales,
  CategorySales,
  DistributionSales,
  DistributionSalesForBuyingOption,
  ProductSales,
  TemplateProductSales,
  TotalSales,
} from '@shared/types/v2/sales'
import { callEndpoint } from './v2'

/** trackHit records a hit to the hit analytics. Scope indicates where the hit took place. */
export async function trackHit(farmId: string, scope: HitScope, deviceType: AnalyticsDeviceType): Promise<void> {
  await callTrackHit(farmId, scope, deviceType)
}

/** trackProductHit tracks a hit for a product.  */
export async function trackProductHit(
  farmId: string,
  productId: string,
  deviceType: AnalyticsDeviceType,
): Promise<void> {
  if (!farmId || !productId || !deviceType) return
  await callTrackHit(farmId, `products.${productId}`, deviceType)
}

/** callTrackHit performs the track hit call operation. It is expected to be called from an exported function that sets up valid input types rather than directly by the outside application. */
async function callTrackHit(farmId: string, scope: string, deviceType: string): Promise<void> {
  if (!farmId || !scope || !deviceType) return

  await callEndpoint('v2.Analytics.hitTrackService', {
    farmId,
    deviceType,
    scope,
  })
}

const analyticsUrl = cloudURLBase('analytics').toString()
const salesUrl = cloudURLBase('sales').toString()

/** The helper to convert JS Date to BigQuery acceptable date format. */
export function convertToBigQueryDateFormat(date: Date, zone?: string) {
  return fromJSDate(date, zone).toISO().slice(0, 10).split('-').join('')
}

/**
 * Fetch analytics data with BQuery from the server.
 *
 * @param {string} type - The type of analytics data to fetch ('analytics', 'dashboardSummary', or 'global').
 * @param {DateValue} dateInfo - An object containing date information for the query like (startDate, endDate, dateTrunc, pastDateInterval).
 */
export async function fetchAnalytics(
  type: 'analytics' | 'dashboardSummary' | 'global',
  dateInfo: DateValue,
  farmId?: string,
  timezone?: string,
  useCache = true,
) {
  let reqUrl = `${analyticsUrl}?type=${type}&useCache=${useCache}&startDate=${convertToBigQueryDateFormat(
    dateInfo.startDate,
    timezone,
  )}&endDate=${convertToBigQueryDateFormat(dateInfo.endDate, timezone)}&dateTrunc=${
    dateInfo.dateTrunc
  }&pastDateInterval=${dateInfo.pastDateInterval}`

  if (farmId) {
    reqUrl += `&farmId=${farmId}`
  }

  return await fetch(reqUrl)
    .then((res) => res.json())
    .then((data) => data)
}

/** Fetches inventory, payouts, openInvoices, distributions, orders data that are generated by BigQuery for AdminDashboard Tables for a specific farm, passing custom flags in the request headers.
 * @param {DashboardDataFlags} flags - The flags to pass in the request headers. These flags are used to specify which data to fetch in the dashboard analytics result. Only data corresponding to flags with a boolean value of true will be fetched for the resulting DashboardResult.
 *
 */
export async function fetchDashboardAnalytics(
  farmId: string,
  flags: DashboardDataFlags,
  useCache = true,
): Promise<DashboardResult> {
  /** pass custom header with flags during https request. */
  const headers = {
    'Content-Type': 'application/json',
    'X-Fetch-Flags': JSON.stringify(flags),
  }

  const reqUrl = `${analyticsUrl}?type=dashboard&useCache=${useCache}&farmId=${farmId}`

  return await fetch(reqUrl, { headers })
    .then((res) => res.json())
    .then((data) => data)
}

/** Retrieves the total sales amount from orders placed within a specified date range, calculating sales using invoice amounts.  */
export async function fetchTotalSales(
  date: DateValue,
  farmId: string,
  timezone: string,
  useCache = true,
): Promise<TotalSales> {
  const reqUrl = `${salesUrl}?&farmId=${farmId}&dataType=total&startDate=${convertToBigQueryDateFormat(
    date.startDate,
    timezone,
  )}&endDate=${convertToBigQueryDateFormat(date.endDate, timezone)}&useCache=${useCache}`

  return await fetch(reqUrl)
    .then((res) => res.json())
    .then((data) => data)
}

/** Retrieves sales data for each product category from orders placed within a specified date range, calculating sales using invoice amounts.  */
export async function fetchCategorySales(
  date: DateValue,
  farmId: string,
  timezone: string,
  useCache = true,
): Promise<CategorySales[]> {
  const reqUrl = `${salesUrl}?&farmId=${farmId}&dataType=categories&startDate=${convertToBigQueryDateFormat(
    date.startDate,
    timezone,
  )}&endDate=${convertToBigQueryDateFormat(date.endDate, timezone)}&useCache=${useCache}`

  return await fetch(reqUrl)
    .then((res) => res.json())
    .then((data) => data)
}

/** Retrieves sales data for all products under each category form orders placed within a specified date range, calculating sales using invoice amounts.  */
export async function fetchProductSalesForCategory(
  date: DateValue,
  farmId: string,
  productIds: string[],
  timezone: string,
  useCache = true,
): Promise<ProductSales[]> {
  const reqUrl = `${salesUrl}?&farmId=${farmId}&dataType=products&startDate=${convertToBigQueryDateFormat(
    date.startDate,
    timezone,
  )}&endDate=${convertToBigQueryDateFormat(date.endDate, timezone)}&productIds=${productIds.join(
    ',',
  )}&useCache=${useCache}`

  return await fetch(reqUrl)
    .then((res) => res.json())
    .then((data) => data)
}

/** Retrieves sales data for all templateProducts under each category from orders placed within a specified date range, calculating sales using invoice amounts. */
export async function fetchTemplateProductSalesForCategory(
  date: DateValue,
  farmId: string,
  templateProductIds: string[],
  timezone: string,
  useCache = true,
): Promise<TemplateProductSales[]> {
  const reqUrl = `${salesUrl}?&farmId=${farmId}&dataType=templateProducts&startDate=${convertToBigQueryDateFormat(
    date.startDate,
    timezone,
  )}&endDate=${convertToBigQueryDateFormat(date.endDate, timezone)}&templateProductIds=${templateProductIds.join(
    ',',
  )}&useCache=${useCache}`

  return await fetch(reqUrl)
    .then((res) => res.json())
    .then((data) => data)
}

/** Retrieves sales data for all products under a templateProduct from orders placed within a specified date range, calculating sales using invoice amounts */
export async function fetchProductSalesForTemplateProduct(
  date: DateValue,
  farmId: string,
  productIds: string[],
  timezone: string,
  useCache = true,
): Promise<ProductSales[]> {
  const reqUrl = `${salesUrl}?&farmId=${farmId}&dataType=productsOfTemplateProduct&startDate=${convertToBigQueryDateFormat(
    date.startDate,
    timezone,
  )}&endDate=${convertToBigQueryDateFormat(date.endDate, timezone)}&productIds=${productIds.join(
    ',',
  )}&useCache=${useCache}`

  return await fetch(reqUrl)
    .then((res) => res.json())
    .then((data) => data)
}

/** Retrieves sales data for all buying options under a product from orders placed within a specified date range, calculating sales using invoice amounts.   */
export async function fetchBuyingOptionsSalesForProduct(
  date: DateValue,
  farmId: string,
  productId: string,
  timezone: string,
  useCache = true,
): Promise<BuyingOptionSales[]> {
  const reqUrl = `${salesUrl}?&farmId=${farmId}&dataType=buyingOptions&startDate=${convertToBigQueryDateFormat(
    date.startDate,
    timezone,
  )}&endDate=${convertToBigQueryDateFormat(date.endDate, timezone)}&productId=${productId}&useCache=${useCache}`

  return await fetch(reqUrl)
    .then((res) => res.json())
    .then((data) => data)
}

/** Retrieves sales data for all distributions under a share product from orders placed within a specified date range, calculating sales using invoice amounts.  */
export async function fetchDistributionSalesForProduct(
  date: DateValue,
  farmId: string,
  productId: string,
  timezone: string,
  useCache = true,
): Promise<DistributionSales[]> {
  const reqUrl = `${salesUrl}?&farmId=${farmId}&dataType=locations&startDate=${convertToBigQueryDateFormat(
    date.startDate,
    timezone,
  )}&endDate=${convertToBigQueryDateFormat(date.endDate, timezone)}&productId=${productId}&useCache=${useCache}`

  return await fetch(reqUrl)
    .then((res) => res.json())
    .then((data) => data)
}

/** Retrieves sales data for all distributions under a buyingOption for a non-share product (the product should have units) from orders placed within a specified date range, calculating sales using invoice amounts.  */
export async function fetchDistributionSalesForBuyingOption(
  date: DateValue,
  farmId: string,
  productId: string,
  buyingOptionId: string,
  timezone: string,
  useCache = true,
): Promise<DistributionSalesForBuyingOption[]> {
  const reqUrl = `${salesUrl}?&farmId=${farmId}&dataType=locationsOfBuyingOption&startDate=${convertToBigQueryDateFormat(
    date.startDate,
    timezone,
  )}&endDate=${convertToBigQueryDateFormat(
    date.endDate,
    timezone,
  )}&productId=${productId}&buyingOptionId=${buyingOptionId}&useCache=${useCache}`

  return await fetch(reqUrl)
    .then((res) => res.json())
    .then((data) => data)
}
