import { formatMoney } from '@helpers/display'
import { BuyingOptionSales, DistributionSales, ProductSales } from '@shared/types/v2/sales'
import { memo, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { DateValue } from '../../Analytics/util'
import { Column, ExpandableRow } from '../../OfflineTable/ExpandableRow'
import { ProductTag } from '../../ProductTag'
import { BuyingOption } from './BuyingOption'
import { Distribution } from './Distribution'
import { LoadingRow, createColumns, indent1 } from './helpers'

import { adminFarmSelector } from '@/redux/selectors'
import { fetchBuyingOptionsSalesForProduct, fetchDistributionSalesForProduct } from '@api/Analytics'
import { isDigital } from '@models/Product'

interface ProductProps {
  data: ProductSales
  index: number
  indent: number
  dateFilter: DateValue
  useCache: boolean
}

export const Product = memo(function Product({ data, index, indent, dateFilter, useCache }: ProductProps) {
  const farm = useSelector(adminFarmSelector)

  const lengthOfSubRows = data.numBuyingOptions || data.numDistributions || 0
  const [buyingOptions, setBuyingOptions] = useState<BuyingOptionSales[] | null>(null) // For standard products
  const [distributions, setDistributions] = useState<DistributionSales[] | null>(null) // For CSA shares

  const buyingOptionElements = useMemo(() => {
    if (!buyingOptions) return null
    return buyingOptions.map((buyingOption, i) => (
      <BuyingOption
        /** hideSubRow will be applied to digital products because digital products has no distributions data should be opened under buyingOptions row */
        hideSubRow={isDigital(data.type)}
        data={buyingOption}
        index={i}
        dateFilter={dateFilter}
        useCache={useCache}
        key={buyingOption.id}
      />
    ))
  }, [buyingOptions, data.type, dateFilter, useCache])

  const distributionElements = useMemo(() => {
    if (!distributions) return null
    return distributions.map((distribution, i) => (
      <Distribution data={distribution} index={i} indent={indent + indent1} key={distribution.id} />
    ))
  }, [distributions, indent])

  const fetchSubRows = useCallback(async () => {
    if (data.numBuyingOptions > 0) {
      const res = await fetchBuyingOptionsSalesForProduct(dateFilter, farm.id, data.id, farm.timezone, useCache)
      setBuyingOptions(res)
    } else if (data.numDistributions > 0) {
      const res = await fetchDistributionSalesForProduct(dateFilter, farm.id, data.id, farm.timezone, useCache)
      setDistributions(res)
    }
  }, [data.numBuyingOptions, data.numDistributions, data.id, dateFilter, farm.id, farm.timezone, useCache])

  const generateSubRows = useCallback(() => {
    if (buyingOptionElements) return buyingOptionElements
    if (distributionElements) return distributionElements
    return [...new Array(lengthOfSubRows)].map((x, i) => <LoadingRow key={i} />)
  }, [buyingOptionElements, distributionElements, lengthOfSubRows])

  const columns = useMemo(
    (): Column<ProductSales>[] =>
      createColumns({
        name(product) {
          return product.name
        },
        sku(product) {
          return product.sku
        },
        type(product) {
          return <ProductTag type={product.type} />
        },
        quantitySold(product) {
          return product.quantitySold.toString()
        },
        grossAmount(product) {
          return formatMoney(product.grossAmount)
        },
      }),
    [],
  )

  return (
    <ExpandableRow<ProductSales>
      item={data}
      index={index}
      onFirstExpand={fetchSubRows}
      columns={columns}
      indent={indent}
      indentMode="first-col"
      generateSubRows={generateSubRows}
    />
  )
})
