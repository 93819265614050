import { formatMoney } from '@helpers/display'
import { MoneyCalc } from '@helpers/money'
import { isShare } from '@models/Product'
import { Schedule } from '@models/Schedule'
import { DateTime } from 'luxon'

import DecimalCalc from '@helpers/decimal'
import { Farm } from './Farm'
import { Location } from './Location'
import { addMoney, makeMoney, Money } from './Money'
import { Order, Pickup, PickupItem, PickupItemStatus } from './Order'
import { getBaseUnit, PaymentSchedule, Product, Share, Unit, UnitStandard } from './Product'
import { DateRange } from './Schedule'
import { User } from './User'
import { PaymentForms } from './PaymentMethod'

export type PaymentStatus = 'paid' | 'due' | 'pending' | 'pending (ACH)' | 'pending (3D)'

// A Summary represents the based properties included in summary reports.

export type Summary = {
  // The summary's date range.

  dateRange: DateRange

  // The number of orders made within the summary range.

  numberOfOrders: number

  // The total gross revenue accrued during the summary range.

  grossRevenue: Money

  // The total net revenue accrued during the summary range.

  netRevenue: Money
}

// DetailSummary identifies a generic summary report type instance used to define a concrete summary type.

export type DetailSummary<T> = Summary & {
  items: T[]
}

// A DailySummary extends a Summary to provide information about purchased items.

export type DailySummary = DetailSummary<SummaryItem<ReportType.Daily>>

// A LocationSummary extends a Summary to include location-specific information.

export type LocationSummary = DetailSummary<LocationSummaryItem>

// The DistributionDetailsSummary extends a Summary to include distribution information.

export type DistributionDetailsSummary = DetailSummary<DistributionSummaryItem>

// The PackListSummary extends a Summary to include customer/order information.

export type PackListSummary = DetailSummary<PackListSummaryItem>

// The SummaryItem provides the line items in the daily summary.

export type SummaryItem<T extends ReportType> = {
  // A reference to the product.

  product: Pick<Product, 'id' | 'type' | 'name' | 'category'> &
    Partial<Pick<UnitStandard, 'baseUnit' | 'producer'>> &
    Pick<Share, 'sku'> &
    Partial<Pick<Share, 'templateProduct'>> & {
      farm: Partial<Pick<Farm, 'name'>>
    }

  // The items ordered.

  selectedUnits: SelectedUnit<T>[]
}

// The LocationSummaryItem provides the line items in the location summary.

export type LocationSummaryItem = {
  // The location being summarized.

  location: Pick<Location, 'id' | 'name' | 'address'>

  // The date of pickup.

  date: DateTime

  // The number of orders in the summary.

  orderCount: number

  // The items associated with the location.

  items: SummaryItem<ReportType.Location>[]
}

// The SignInSummaryItem provides the line items in the pack list sheet summary.

export type PackListSummaryItem = {
  // The customer account associated with the purchase.

  user: Pick<User, 'id' | 'name' | 'phoneNumber' | 'email' | 'email2' | 'address'>

  // The order associated with the distribution.
  order: Pick<Order, 'orderNum' | 'date'>

  // The location where the items will be distributed.

  location: Pick<Location, 'id' | 'name' | 'type' | 'address'> & Pick<Partial<Schedule>, 'hours'>

  // The current payment status for the current distribution.

  paymentStatus: PaymentStatus

  // The payment schedule.

  paymentSchedule: Pick<PaymentSchedule, 'paymentType' | 'frequency'>

  // The date when the purchase will be distributed.

  deliveryDate: DateTime

  // The associated item.

  items: SummaryItem<ReportType.PackList>[]
}

// The DistributionItem provides the line items in the distribution summary.

export type DistributionSummaryItem = {
  // The customer account associated with the purchase.

  user: Pick<User, 'id' | 'name' | 'phoneNumber' | 'email' | 'email2' | 'address'>

  // The order associated with the distribution.

  order: Pick<Order, 'id' | 'orderNum'>

  // The current pickup Id.

  pickup: Pick<Pickup, 'id'>

  // The current pickupItem Id.

  pickupItem: Pick<PickupItem, 'id'>

  // The location where the items will be distributed.

  location: Pick<Location, 'id' | 'name' | 'address' | 'type'>

  // The current payment status for the current distribution.

  paymentStatus: PaymentStatus

  // The total amount due for the order. (from all invoices associated with the order)

  order_due_amounts?: Money

  // The payment schedule.

  paymentSchedule: Pick<PaymentSchedule, 'paymentType' | 'frequency'>

  // The date when the purchase will be distributed.

  deliveryDate: DateTime

  // The associated item.

  item: SummaryItem<ReportType.SignIn>
}

export enum ReportType {
  Daily = 'daily',
  Location = 'location',
  SignIn = 'signIn',
  PackList = 'packList',
}

// SelectedUnit identifies a product item that has been selected.

export type SelectedUnit<T extends ReportType> = {
  unit?: Unit

  quantity: number

  amount: Money
  /** @prop status will be used in SignInSheet BigQuery to show all items include all their statuses (Currently, status data is used in signInSheet CSV and digital SignInSheet ) */
  status: T extends ReportType.SignIn ? PickupItemStatus : undefined
}

// formatPaymentStatus returns a formatted payment status string.

export function formatPaymentStatus(paymentStatus: PaymentStatus): string {
  return paymentStatus.toUpperCase()
}

/** formatPaymentMethod returns a formatted payment method string. */
export function formatPaymentMethod(method: PaymentForms): string {
  if (method === PaymentForms.CASH) {
    return 'Offline'
  } else if (method === PaymentForms.EBT) {
    return 'EBT'
  } else if (method === PaymentForms.FARM_CREDIT) {
    return 'Farm Credit'
  } else if (method === PaymentForms.BANK) {
    return 'Bank'
  } else {
    return 'Card'
  }
}

// producer returns the name of the producer that sold the item. By default the producer is the farm that created
// the product.

export function producer(item: SummaryItem<ReportType>): string | undefined {
  let producer
  if ('producer' in item.product) {
    producer = item.product.producer
  }
  if (!producer) {
    producer = item.product.farm.name
  }
  return producer
}

// TODO: DRY this. Is similar to orderedUnits.

export function quantity(item: SummaryItem<ReportType>): string {
  return orderedUnits(item).toString()
}

// orderedUnits returns the number of units ordered relative to the base unit.

export function orderedUnits(item: SummaryItem<ReportType>): number {
  let total = 0
  for (const selectedUnit of item.selectedUnits) {
    if (!selectedUnit.unit) {
      total += selectedUnit.quantity
      continue
    }
    total = DecimalCalc.add(total, DecimalCalc.multiply(selectedUnit.quantity, selectedUnit.unit.multiplier))
  }
  return total
}

// summaryTotal returns the total amount of the summary.

export function summaryTotal(item: SummaryItem<ReportType>): Money {
  let total = makeMoney(0)
  for (const selectedUnit of item.selectedUnits) {
    total = addMoney(total, selectedUnitTotal(selectedUnit))
  }
  return total
}

// sku returns the SKU related to the supplied summary item.

export function sku(item: SummaryItem<ReportType>, selectedUnit: SelectedUnit<ReportType>): string | undefined {
  if (isShare(item.product.type)) {
    return item.product.sku
  }
  if (selectedUnit.unit) {
    return selectedUnit.unit.sku
  }

  return undefined
}

// selectedUnitName returns the name of the unit for the supplied item. If the selected unit is not available,
// the base unit name will be returned instead.

export function selectedUnitName(item: SummaryItem<ReportType>, selectedUnit: SelectedUnit<ReportType>): string {
  if (!selectedUnit.unit?.name) {
    return getBaseUnit(item.product)
  }
  return selectedUnit.unit.name
}

// selectedUnitPrice returns the per-unit amount of the selected item.

export function selectedUnitPrice(selectedUnit: SelectedUnit<ReportType>): string {
  return formatMoney(MoneyCalc.divide(selectedUnit.amount, selectedUnit.quantity))
}

// selectedUnitStatus returns the status of the selected pickupItem (product) for singInSheet.

export function selectedUnitStatus(selectedUnit: SelectedUnit<ReportType.SignIn>): string {
  return selectedUnit.status.toUpperCase()
}

// qtyOfBuyingOption returns the quantity of the selected item.

export function qtyOfBuyingOption(selectedUnit: SelectedUnit<ReportType>): number {
  if (selectedUnit.status === PickupItemStatus.Cancelled || selectedUnit.status === PickupItemStatus.Vacation) {
    return 0
  } else {
    return selectedUnit.quantity
  }
}

// selectedUnitTotal returns the full amount of the selected item.

export function selectedUnitTotal(selectedUnit: SelectedUnit<ReportType>): Money {
  return selectedUnit.amount
}

// summaryEmails returns a collection of all of the emails made available in the distribution summary.
// The results are sorted alphabetically.

export function summaryEmails(summary: DistributionDetailsSummary): string[] {
  const emails = new Set<string>()

  function addEmail(email: string | undefined) {
    if (!email) {
      return
    }

    const trimmedEmail = email.trim()
    if (trimmedEmail.length === 0) {
      return
    }
    emails.add(trimmedEmail.toLowerCase())
  }

  for (const { user } of summary.items) {
    addEmail(user.email)
    addEmail(user.email2)
  }

  return Array.from(emails).sort()
}
