import { httpsCallable } from 'firebase/functions'

import { DateRange, SummaryFilter } from '../models/Schedule'
import { DailySummary, DistributionDetailsSummary, LocationSummary, PackListSummary } from '../models/Summary'
import { functions } from './db'
import { marshalDateRange } from './encoding/Schedule'
import {
  unmarshalDailySummary,
  unmarshalDistributionDetailsSummary,
  unmarshalLocationSummary,
  unmarshalPackListSummary,
} from './encoding/Summary'

/** loadDailySummary provides the data to report on items available to be purchased within the supplied date range for the requested farm.
 * @param farmId - the id of the farm from where the data is fetched
 * @param dateRange - the date range of the `pickups date` that will be fetched
 * @param filter - any additional filters for the data, e.g. Location
 * @param useCache - whether to use the cache table or latest table, default is true
 */
export async function loadDailySummary(
  farmId: string,
  dateRange: DateRange,
  filter?: SummaryFilter,
  useCache = true,
): Promise<DailySummary> {
  const response = await httpsCallable(
    functions(),
    'Summaries-loadDailySummaryCall',
  )({
    farmId,
    dateRange: marshalDateRange(dateRange),
    filter,
    useCache,
  })
  return unmarshalDailySummary(response.data)
}

/** loadLocationSummary provides a summary of items purchased for pickup at a collection of locations within the supplied date range for the requested farm.
 * @param farmId - the id of the farm from where the data is fetched
 * @param dateRange - the date range of the `pickups date` that will be fetched
 * @param filter - any additional filters for the data, e.g. Location
 * @param useCache - whether to use the cache table or latest table, default is true
 */
export async function loadLocationSummary(
  farmId: string,
  dateRange: DateRange,
  filter?: SummaryFilter,
  useCache = true,
): Promise<LocationSummary> {
  const response = await httpsCallable(
    functions(),
    'Summaries-loadLocationSummaryCall',
  )({
    farmId,
    dateRange: marshalDateRange(dateRange),
    filter,
    useCache,
  })
  return unmarshalLocationSummary(response.data)
}

/**
 * loadDistributionDetails provides details about customers and the products they have ordered that will be
distributed within the supplied date range for the requested farm
 * @param farmId - the id of the farm from where the data is fetched
 * @param dateRange - the date range of the `pickups date` that will be fetched
 * @param filter - any additional filters for the data, e.g. Location
 * @param useCache - whether to use the cache table or latest table, default is true
 * @returns 
 */
export async function loadDistributionDetails(
  farmId: string,
  dateRange: DateRange,
  filter?: SummaryFilter,
  useCache = true,
): Promise<DistributionDetailsSummary> {
  const response = await httpsCallable(
    functions(),
    'Summaries-loadDistributionDetailsCall',
  )({
    farmId,
    dateRange: marshalDateRange(dateRange),
    filter,
    useCache,
  })
  return unmarshalDistributionDetailsSummary(response.data)
}

/** loadPackListSummary provides details about customers and the pickups PER order within the supplied date range for the requested farm.
 * @param farmId - the id of the farm from where the data is fetched
 * @param dateRange - the date range of the `pickups date` that will be fetched
 * @param filter - any additional filters for the data, e.g. Location
 * @param useCache - whether to use the cache table or latest table, default is true
 */
export async function loadPackListSummary(
  farmId: string,
  dateRange: DateRange,
  filter?: SummaryFilter,
  useCache = true,
): Promise<PackListSummary> {
  const response = await httpsCallable(
    functions(),
    'Summaries-loadPackListSummaryCall',
  )({
    farmId,
    dateRange: marshalDateRange(dateRange),
    filter,
    useCache,
  })
  return unmarshalPackListSummary(response.data)
}
