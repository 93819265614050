import { hasOwnProperty } from '@helpers/helpers'
import { MoneyCalc } from '@helpers/money'
import { Invoice, InvoicePaymentMethod, InvoiceStatus, isOffline, isPaid, PaymentSources } from '@models/Invoice'
import { Zero } from '@models/Money'
import { formatPaymentMethod } from '@models/Summary'
import { capitalize } from './display'
import { PaymentForms } from '@models/PaymentMethod'

/** display paymentMethod with custom logic to be used in Admin Customer invoice section and SummaryInvoices report */
export const displayPaymentMethod = (invoice: Invoice): string => {
  // if the invoice is due and offline, we need to show paymentMethod empty string
  if (invoice.status === InvoiceStatus.Due && isOffline(invoice)) return ''
  else return chainPaymentsMethod(invoice, true).join(', ')
}

/** single payment method formatting helper */
export function formatSinglePaymentMethod(source: PaymentSources, invoice: Invoice): string {
  // if invoice is due and offline, do not show payment method
  if (invoice.status === InvoiceStatus.Due && isOffline(invoice)) {
    return ''
  } else if (source === PaymentSources.WORLD_PAY_EBT) {
    return formatPaymentMethod(PaymentForms.EBT)
  } else if (source === PaymentSources.OFFLINE) {
    return formatPaymentMethod(PaymentForms.CASH)
  } else if (source === PaymentSources.FARM_CREDIT) {
    return formatPaymentMethod(PaymentForms.FARM_CREDIT)
  } else if (source === PaymentSources.STRIPE_ACH) {
    const last4 = (
      invoice.payments.stripe_ach?.paymentMethod as InvoicePaymentMethod<PaymentSources.STRIPE_ACH> | undefined
    )?.last4
    const cardType = (
      invoice.payments.stripe_ach?.paymentMethod as InvoicePaymentMethod<PaymentSources.STRIPE_ACH> | undefined
    )?.bank_name
    return last4 ? `${capitalize(cardType || 'Bank')}*${last4}` : 'Bank'
  } else {
    if (hasOwnProperty(invoice.payments, PaymentSources.STRIPE)) {
      const last4 = (invoice.payments.stripe?.paymentMethod as InvoicePaymentMethod<PaymentSources.STRIPE> | undefined)
        ?.last4
      const cardType = (
        invoice.payments.stripe?.paymentMethod as InvoicePaymentMethod<PaymentSources.STRIPE> | undefined
      )?.card_type
      return last4 ? `${capitalize(cardType || 'Card')}*${last4}` : 'Card'
    }
    return formatPaymentMethod(PaymentForms.CARD)
  }
}

/** for split-tender payments to get all payment methods,
 * @arg inv the invoice to get payment methods from
 * @arg expandDetails (optional) default (false) - if true, will return payment methods with details, especially for Stripe payment now. (e.g. 'Visa*1234')
 * for example,
 * expandDetails is false return ['Offline', 'Card', 'EBT', 'Farm Credit']
 * expandDetails is true return ['Offline', 'Visa*1234', 'EBT', 'Farm Credit']
 */
export function chainPaymentsMethod(inv: Invoice, expandDetails = false): string[] {
  const paymentsGroup = inv.payments
  const chainResults: string[] = []

  const invPaid = isPaid(inv)

  if (hasOwnProperty(paymentsGroup, PaymentSources.STRIPE_INVOICE)) {
    if (paymentsGroup.stripe_invoice!.paymentMethod.id === 'cash')
      chainResults.push(formatPaymentMethod(PaymentForms.CASH))
    else chainResults.push(formatPaymentMethod(PaymentForms.CARD))
  }
  //only show when stripe payment is greater than zero
  if (hasOwnProperty(paymentsGroup, PaymentSources.STRIPE)) {
    const last4 = (paymentsGroup.stripe!.paymentMethod as InvoicePaymentMethod<PaymentSources.STRIPE>).last4
    const cardType = (paymentsGroup.stripe!.paymentMethod as InvoicePaymentMethod<PaymentSources.STRIPE>).card_type
    const cardText = last4 ? `${capitalize(cardType || 'Card')}*${last4}` : 'Card'
    if (invPaid) {
      if (MoneyCalc.isGTE(paymentsGroup.stripe!.totalPaid, Zero))
        chainResults.push(expandDetails ? cardText : formatPaymentMethod(PaymentForms.CARD))
    } else chainResults.push(expandDetails ? cardText : formatPaymentMethod(PaymentForms.CARD))
  }
  if (hasOwnProperty(paymentsGroup, PaymentSources.STRIPE_ACH)) {
    const last4 = (paymentsGroup.stripe_ach!.paymentMethod as InvoicePaymentMethod<PaymentSources.STRIPE_ACH>).last4
    const achText = last4 ? `Bank*${last4}` : 'Bank'
    if (invPaid) {
      if (MoneyCalc.isGTE(paymentsGroup.stripe_ach!.totalPaid, Zero))
        chainResults.push(expandDetails ? achText : formatPaymentMethod(PaymentForms.BANK))
    } else chainResults.push(expandDetails ? achText : formatPaymentMethod(PaymentForms.BANK))
  }
  if (hasOwnProperty(paymentsGroup, PaymentSources.WORLD_PAY_EBT)) {
    if (invPaid) {
      if (MoneyCalc.isGTE(paymentsGroup.worldpay_ebt!.totalPaid, Zero))
        chainResults.push(formatPaymentMethod(PaymentForms.EBT))
    } else chainResults.push(formatPaymentMethod(PaymentForms.EBT))
  }
  if (hasOwnProperty(paymentsGroup, PaymentSources.FARM_CREDIT)) {
    if (invPaid) {
      if (MoneyCalc.isGTE(paymentsGroup.farmcredit!.totalPaid, Zero))
        chainResults.push(formatPaymentMethod(PaymentForms.FARM_CREDIT))
    } else chainResults.push(formatPaymentMethod(PaymentForms.FARM_CREDIT))
  }
  if (hasOwnProperty(paymentsGroup, PaymentSources.OFFLINE)) {
    if (invPaid) {
      if (MoneyCalc.isGTE(paymentsGroup.offline!.totalPaid, Zero))
        chainResults.push(formatPaymentMethod(PaymentForms.CASH))
    } else chainResults.push(formatPaymentMethod(PaymentForms.CASH))
  }

  return chainResults
}
