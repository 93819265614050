import { AdminProductListContextType } from '@/admin/screens/Products/AdminProductListScreen/AdminProductListScreen'
import Colors from '@/constants/Colors'
import { ModalComponentProps } from '@/constants/types/modalTypes'
import { loadCSAsByFarm } from '@api/CSAs'
import { loadFarmCategoriesFromId } from '@api/Categories'
import { productHasSales } from '@api/Products'
import { farmsCollection, productsCollection } from '@api/framework/ClientCollections'
import { DropdownMenuBtn } from '@elements'
import { ProductFields, buildProduct, isValidEbtEligibility } from '@helpers/builders/buildProduct'
import { getReadableEbtEligibility } from '@helpers/products-display'
import { ArrElement, includes, isOfType, keys, pick } from '@helpers/typescript'
import { EbtEligibility, Product, hasUnits, isPhysical, isStandard } from '@models/Product'
import { PickerItemProps } from '@react-native-picker/picker'
import { openBulkDelete } from '../BulkDelete'
import { BulkEditBooleanProps, openBulkEditBoolean } from '../BulkEditBoolean'
import { BulkEditSelectorCompProps, openBulkEditSelector } from '../BulkEditSelector'
import { OpenBulkEditProductFeesOpts, openBulkEditProductFees } from './BulkEditProductFees'
import { OpenBulkEditProductSchedulesOpts, openBulkEditProductSchedules } from './BulkEditProductSchedules'

/** PRODUCT BOOLEAN------------------------------------ */

type ProductBulkeditBooleanFields = Extract<
  keyof ProductFields,
  'isHidden' | 'isFeatured' | 'hideFromShop' | 'disableBuyInFuture'
>

/** Map to extract the needed helpers for each boolean bulkedit product field */
const prodBulkBoolFieldData: Record<
  ProductBulkeditBooleanFields,
  Pick<BulkEditBooleanProps<Product>, 'fieldNameDisplay' | 'getField' | 'updateField'> &
    Pick<ModalComponentProps, 'title'>
> = {
  isFeatured: {
    fieldNameDisplay: 'Feature',
    getField: (p) => p.isFeatured,
    updateField: (v, p) => ({ ...p, isFeatured: v }),
    title: 'Feature Products',
  },
  isHidden: {
    fieldNameDisplay: 'Hide',
    getField: (p) => p.isHidden,
    updateField: (v, p) => ({ ...p, isHidden: v }),
    title: 'Hide Products',
  },
  hideFromShop: {
    fieldNameDisplay: 'Only-CSA pages',
    getField: (p) => (hasUnits(p) ? p.hideFromShop : 'Field_not_exist'),
    updateField: (v, p) => (hasUnits(p) ? { ...p, hideFromShop: v } : p),
    title: 'Show on CSA pages',
  },
  disableBuyInFuture: {
    fieldNameDisplay: 'Next-Day Only',
    getField: (p) => (isStandard(p) ? p.disableBuyInFuture : 'Field_not_exist'),
    updateField: (v, p) => (isStandard(p) ? { ...p, disableBuyInFuture: v } : p),
    title: 'Only allow next-day purchases',
  },
}

/** The fields supported for boolean bulk editing. This array should always be up-to-date */
const productBulkeditBooleanFields = keys(prodBulkBoolFieldData)

type OpenBulkeditProdsBooleanOpts = Pick<BulkEditModalGenericProps<Product, boolean>, 'ids'> & {
  field: ProductBulkeditBooleanFields
  onSuccess?: BulkEditModalGenericProps<Product, boolean>['onPassValidation']
}

/** opens the boolean bulk edit modal with configuration for products */
function openBulkEditProductsBoolean({ ids, field, onSuccess }: OpenBulkeditProdsBooleanOpts) {
  const { fieldNameDisplay, getField, updateField, title } = prodBulkBoolFieldData[field]

  openBulkEditBoolean({
    contentProps: {
      ids,
      fetchByIds: productsCollection.fetchByIds.bind(productsCollection),
      fieldNameDisplay,
      getField,
      onPassValidation: async (newData) => {
        await Promise.all(
          newData.map((updatedProd) => {
            const newValue = getField(updatedProd)
            if (newValue === 'Field_not_exist') return Promise.resolve()
            return productsCollection.update({ id: updatedProd.id, [field]: newValue })
          }),
        )
        onSuccess?.(newData)
      },
      updateField,
      validate: buildProduct,
    },
    title: title || fieldNameDisplay,
  })
}

/** PRODUCT SELECTOR------------------------------------ */

type ProductBulkeditSelectorFields = Extract<keyof ProductFields, 'csa' | 'producer' | 'category' | 'ebtEligibility'>

const prodBulkSelectorFieldData: Record<
  ProductBulkeditSelectorFields,
  Pick<
    BulkEditSelectorCompProps<Product>,
    'fieldNameDisplay' | 'getField' | 'getUpdate' | 'updateField' | 'createUnitOpts'
  > &
    Pick<ModalComponentProps, 'title'>
> = {
  category: {
    fieldNameDisplay: 'Category',
    getField: (p) => p.category,
    updateField: (v, p) => ({ ...p, category: v }),
  },
  csa: {
    fieldNameDisplay: 'CSA Group',
    getField: (p) => p.csa?.[0],
    getUpdate: (p) => pick(p, 'csa'),
    updateField: (v, p) => ({ ...p, csa: [v] }),
  },
  producer: {
    fieldNameDisplay: 'Producer',
    getField: (p) => (isPhysical(p) ? p.producer : 'Field_not_exist'),
    updateField: (v, p) => (isPhysical(p) ? { ...p, producer: v } : p),
  },
  ebtEligibility: {
    fieldNameDisplay: 'SNAP/EBT Eligibility',
    getField: (p) => (isStandard(p) ? p.ebtEligibility : 'Field_not_exist'),
    updateField: (v, p) => (isStandard(p) && isValidEbtEligibility(v) ? { ...p, ebtEligibility: v } : p),
  },
}

const productBulkeditSelectorFields = keys(prodBulkSelectorFieldData)

const selectorFieldsWithCreation = ['category', 'producer'] as ('category' | 'producer')[]

const buildGetSelectorOptions =
  (field: ProductBulkeditSelectorFields, farmId: string) => async (): Promise<PickerItemProps<string>[]> => {
    switch (field) {
      case 'category': {
        const categories = await loadFarmCategoriesFromId(farmId)
        return categories.map((cat) => ({
          label: cat.name,
          value: cat.name,
          color: cat.isDefault ? Colors.brown : undefined,
        }))
      }
      case 'csa': {
        const farmCsas = await loadCSAsByFarm(farmId)
        return farmCsas.map((csa) => ({
          label: `${csa.name}${csa.isHidden ? ' (Hidden)' : ''}`,
          value: csa.id,
          color: csa.isHidden ? Colors.brown : undefined,
        }))
      }
      case 'producer': {
        const farm = await farmsCollection.fetch(farmId)
        return (farm.localProducers ?? []).map((producer) => ({
          label: producer,
          value: producer,
        }))
      }
      case 'ebtEligibility': {
        // Array of picker items for EBT eligibility. This array contains objects with `label` and `value` properties representing different EBT eligibility options.
        return Object.values(EbtEligibility).map((value) => ({
          label: getReadableEbtEligibility(value),
          value,
        }))
      }
      default:
        throw new Error('This is not a registered bulk selector field to get options')
    }
  }

type OpenBulkeditProdsSelectorOpts = {
  field: ProductBulkeditSelectorFields
  onSuccess?: BulkEditModalGenericProps<Product, string>['onPassValidation']
  farmId: string
} & Pick<BulkEditModalGenericProps<Product, string>, 'ids'>

/** opens the selector bulk edit modal with configuration for products */
function openBulkEditProductsSelector({ ids, field, onSuccess, farmId }: OpenBulkeditProdsSelectorOpts) {
  const { fieldNameDisplay, getField, getUpdate, updateField, title } = prodBulkSelectorFieldData[field]

  openBulkEditSelector({
    contentProps: {
      ids,
      fetchByIds: productsCollection.fetchByIds.bind(productsCollection),
      fieldNameDisplay,
      getField,
      onPassValidation: async (newData) => {
        await Promise.all(
          newData.map((updatedProd) => {
            const newValue = getField(updatedProd)
            if (newValue === 'Field_not_exist') return Promise.resolve()

            const update: Partial<Product> = getUpdate?.(updatedProd) ?? { [field]: newValue }
            return productsCollection.update({ id: updatedProd.id, ...update })
          }),
        )
        onSuccess?.(newData)
      },
      updateField,
      validate: buildProduct,
      getSelectorOptions: buildGetSelectorOptions(field, farmId),
      createUnitOpts: includes(selectorFieldsWithCreation, field)
        ? { farmId, type: field, message: field === 'producer' ? 'Producer Name' : undefined }
        : undefined,
    },
    title: title || fieldNameDisplay,
  })
}

/** PRODUCT DELETE------------------------------------ */

type OpenBulkDeleteProdsOpts = {
  ids: string[]
  farmId: string
  field: 'DELETE'
  /** onSuccess will receive the ids that were deleted */
  onSuccess?: (ids: string[]) => void
}

const openBulkDeleteProducts = ({ ids, farmId, onSuccess }: OpenBulkDeleteProdsOpts) => {
  openBulkDelete({
    contentProps: {
      ids,
      fetchByIds: productsCollection.fetchByIds.bind(productsCollection),
      modelDisplayName: 'products',
      validate: async (id) => {
        const hasSales = await productHasSales(id, farmId)
        // This must return an error with the reason why the product can't be deleted. This error message will be shown in the bulkDelete UI along with the product name
        if (hasSales) throw new Error('Product has sales')
      },
      onPassValidation: async (ids) => {
        await Promise.all(ids.map((id) => productsCollection.delete(id)))
        return onSuccess?.(ids)
      },
    },
    title: `Delete Products`,
  })
}

/** PRODUCT SCHEDULES------------------------------------ */

type OpenBulkEditProdSchedulesAdapterOpts = OpenBulkEditProductSchedulesOpts['contentProps'] & {
  /** "field" is not needed by the modal, but it is needed here for the main openBulkEditProducts to identify when the options belong to this editor */
  field: 'distributions'
}

function openBulkEditProductSchedulesAdapter({ ids, onSuccess }: OpenBulkEditProdSchedulesAdapterOpts) {
  openBulkEditProductSchedules({
    contentProps: { ids, onSuccess },
  })
}

/** PRODUCT TAXES & FEES ------------------------------------ */

type OpenBulkEditProductFeesAdapterOpts = OpenBulkEditProductFeesOpts['contentProps'] & {
  /** "field" is not needed by the modal, but it is needed here for the main openBulkEditProducts to identify when the options belong to this editor */
  field: 'productFee'
}

function openBulkEditProductFeesAdapter({ ids, onSuccess }: OpenBulkEditProductFeesAdapterOpts) {
  openBulkEditProductFees({
    contentProps: { ids, onSuccess },
  })
}

/** PRODUCT MAIN------------------------------------ */

type OpenBulkEditProductsOptsGeneral =
  | OpenBulkeditProdsBooleanOpts
  | OpenBulkeditProdsSelectorOpts
  | OpenBulkDeleteProdsOpts
  | OpenBulkEditProdSchedulesAdapterOpts
  | OpenBulkEditProductFeesAdapterOpts

/** This function is the entry point for bulk editing products. The caller only needs to specify the products and the field for editing, although some of the types of editors may require additional opts based on the field. Typescript should show the full options expected for any given editor in accordance to the field */
export function openBulkEditProducts(opts: OpenBulkEditProductsOptsGeneral) {
  if (isOfType<OpenBulkeditProdsBooleanOpts>(opts, includes(productBulkeditBooleanFields, opts.field))) {
    openBulkEditProductsBoolean(opts)
  } else if (isOfType<OpenBulkeditProdsSelectorOpts>(opts, includes(productBulkeditSelectorFields, opts.field))) {
    openBulkEditProductsSelector(opts)
  } else if (opts.field === 'DELETE') {
    openBulkDeleteProducts(opts)
  } else if (opts.field === 'distributions') {
    openBulkEditProductSchedulesAdapter(opts)
  } else if (opts.field === 'productFee') {
    openBulkEditProductFeesAdapter(opts)
  } else {
    throw new Error('Invalid options for opening the product bulk editor')
  }
}

/** These are all the fields that will appear inside the dropdown menu that shows the bulk edit options on multiple selection */
const productBulkeditAllFields = [
  ...productBulkeditBooleanFields,
  ...productBulkeditSelectorFields,
  'distributions' as const,
  'DELETE' as const,
  'productFee' as const,
]

const getSelectedIds = (selection: AdminProductListContextType['selection']) =>
  Object.entries(selection)
    .filter(([_, v]) => !!v)
    .map(([k, _]) => k)

const getFieldDisplayName = (field: ArrElement<typeof productBulkeditAllFields>) =>
  includes(productBulkeditBooleanFields, field)
    ? prodBulkBoolFieldData[field].fieldNameDisplay
    : includes(productBulkeditSelectorFields, field)
    ? prodBulkSelectorFieldData[field].fieldNameDisplay
    : field === 'DELETE'
    ? 'Delete'
    : field === 'distributions'
    ? 'Schedules'
    : field === 'productFee'
    ? 'Taxes & Fees'
    : field

/** These get passed to a dropdown menu. When a field is selected it will open the main entry modal */
export const dropdownMenuFields: DropdownMenuBtn<{
  selection: AdminProductListContextType['selection']
  farmId: string
  onSuccessEdit: BulkEditModalGenericProps<Product, any>['onPassValidation']
  /** It's necessary to have a separate onSuccess for delete because since nothing was edited only the ids are returned */
  onSuccessDelete: OpenBulkDeleteProdsOpts['onSuccess']
}>[] = productBulkeditAllFields.map((field) => ({
  title: getFieldDisplayName(field),
  onPress: ({ selection, onSuccessEdit, onSuccessDelete, farmId }) => {
    if (includes(productBulkeditBooleanFields, field)) {
      openBulkEditProducts({
        ids: getSelectedIds(selection),
        field,
        onSuccess: onSuccessEdit,
      })
    } else if (includes(productBulkeditSelectorFields, field)) {
      openBulkEditProducts({
        ids: getSelectedIds(selection),
        field,
        onSuccess: onSuccessEdit,
        farmId,
      })
    } else if (field === 'DELETE') {
      openBulkEditProducts({
        ids: getSelectedIds(selection),
        farmId,
        field,
        onSuccess: onSuccessDelete,
      })
    } else if (field === 'distributions' || field === 'productFee') {
      openBulkEditProducts({ field, ids: getSelectedIds(selection), onSuccess: onSuccessEdit })
    }
  },
}))

export type BulkEditModalGenericProps<T extends { id: string }, FieldType extends any> = {
  /** selection of items by id */
  ids: string[]
  /** api cb to fetch the docs from the ids */
  fetchByIds: (ids: string[]) => Promise<T[]>
  /** field name to display next to the checkbox or selector or input component */
  fieldNameDisplay: string
  /** obtains the field value from the document object. If the field is not supported by a subtype of T, it should return 'Field_not_exist' */
  getField: (obj: T) => FieldType | 'Field_not_exist'
  /** getUpdate should return the input for the database update api. This is optional. If undefined, the update will be the value from getField. Only use this if the field type is different from the getField value, for example: Product['csa'] the getField value is the csaId but the getUpdate value is the array of ids */
  getUpdate?: (obj: T) => Partial<T>
  /** This must update the field within the object with the new value, and return the updated object. If the field doesn't exist in the doc, this should return the unchanged doc */
  updateField: (newVal: NonNullable<FieldType>, doc: T) => T
  /** validation is assumed to throw errors whose messages will be displayed in the UI */
  validate: (obj: T) => void
  /** api cb for updating db with the modified data (data with edits applied by the "updateField" helper) after validation passes. this should skip updating those docs for which the field doesn't exist, which either way will be unchanged because "updateField" should also have left them intact */
  onPassValidation: (newData: T[]) => void | Promise<void>
  /** how to represent the doc as text for the ui. This should extract the item's name or other identifier relevant for the user */
  getItemDisplayName?: (obj: T) => string
}
