import { CategorySales, TotalSales } from '@shared/types/v2/sales'

import { propsAreDeepEqual } from '@helpers/client/propsAreDeepEqual'
import { memo } from 'react'
import { DateValue } from '../../Analytics/util'
import { OfflineTable } from '../../OfflineTable/OfflineTable'
import { Category } from './Category'

interface SalesTableProps {
  total: TotalSales | null
  data: CategorySales[] | null
  dateFilter: DateValue
  useCache: boolean
}

interface SalesTableRow extends CategorySales {
  isTotal?: boolean
}

export const SalesTable = memo(function SalesTable({ total, data, dateFilter, useCache }: SalesTableProps) {
  const totalRow: SalesTableRow = {
    name: 'Total',
    isTotal: true,
    grossAmount: total?.grossAmount ?? 0,
    quantitySold: total?.quantitySold ?? 0,
    productIds: [],
    templateProductIds: [],
  }

  return (
    <OfflineTable<SalesTableRow>
      headerColumns={[
        {
          title: 'Name',
          widthFlex: 2,
        },
        { title: 'SKU', widthFlex: 1 },
        {
          title: 'Type',
          widthFlex: 1,
        },
        {
          title: 'Quantity Sold',
          widthFlex: 1,
        },
        {
          title: 'Total',
          widthFlex: 1,
        },
      ]}
      isLoading={!total || !data}
      renderItem={(info) => (
        <Category
          data={info.item}
          useCache={useCache}
          index={info.index}
          dateFilter={dateFilter}
          isTotal={info.item.isTotal}
          key={info.item.name}
        />
      )}
      data={data?.length ? data.concat(totalRow) : []}
    />
  )
}, propsAreDeepEqual)
