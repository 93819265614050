import { formatMoney } from '@helpers/display'
import { ProductSales, TemplateProductSales } from '@shared/types/v2/sales'
import { memo, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { DateValue } from '../../Analytics/util'
import { Column, ExpandableRow } from '../../OfflineTable/ExpandableRow'
import { Tag } from '../../ProductTag'
import { Product } from './Product'
import { LoadingRow, createColumns, indent2 } from './helpers'

import Colors from '@/constants/Colors'
import { adminFarmSelector } from '@/redux/selectors'
import { fetchProductSalesForTemplateProduct } from '@api/Analytics'

interface TemplateProductProps {
  data: TemplateProductSales
  index: number
  dateFilter: DateValue
  useCache: boolean
  indent: number
}

export const TemplateProduct = memo(function TemplateProduct({
  data,
  index,
  dateFilter,
  useCache,
  indent,
}: TemplateProductProps) {
  const farm = useSelector(adminFarmSelector)

  const [products, setProducts] = useState<ProductSales[] | null>(null)

  const fetchProducts = useCallback(async () => {
    const res = await fetchProductSalesForTemplateProduct(dateFilter, farm.id, data.productIds, farm.timezone, useCache)
    setProducts(res)
  }, [data.productIds, dateFilter, farm.id, farm.timezone, useCache])

  const generateSubRows = useCallback(
    (templateProduct: TemplateProductSales) => {
      if (!products) return [...new Array(templateProduct.productIds.length)].map((x, i) => <LoadingRow key={i} />)
      return products.map((product, i) => (
        <Product
          data={product}
          index={i}
          indent={indent2}
          dateFilter={dateFilter}
          useCache={useCache}
          key={product.id}
        />
      ))
    },
    [products, dateFilter, useCache],
  )

  const columns = useMemo(
    (): Column<TemplateProductSales>[] =>
      createColumns({
        name(product) {
          return product.name
        },
        sku(product) {
          return product.sku
        },
        type() {
          return <Tag color={Colors.red} title="Share Template" />
        },
        quantitySold(product) {
          return product.quantitySold.toString()
        },
        grossAmount(product) {
          return formatMoney(product.grossAmount)
        },
      }),
    [],
  )

  return (
    <ExpandableRow<TemplateProductSales>
      item={data}
      index={index}
      onFirstExpand={fetchProducts}
      columns={columns}
      indent={indent}
      indentMode="first-col"
      generateSubRows={generateSubRows}
    />
  )
})
