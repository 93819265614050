import { ToolTips } from '@components'
import { HeaderText, Tooltip } from '@elements'
import { openSalesCSV } from '@helpers/links'
import { CategorySales, TotalSales } from '@shared/types/v2/sales'
import { useCallback, useState } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from 'rn-responsive-styles'

import { Permission } from '@helpers/Permission'
import { AdminView } from '../components/AdminView'
import { DatePicker, DateRangeNames } from '../components/Analytics/DatePicker'
import { DateValue, getYearToDate } from '../components/Analytics/util'
import Export from '../components/Export'
import { SalesTable } from '../components/Sales/Table'

import { useFocusFx } from '@/hooks/useFocusFx'
import { withAdminAuth } from '@/hooks/withAdminAuth'
import { adminFarmSelector } from '@/redux/selectors'
import { fetchCategorySales, fetchTotalSales } from '@api/Analytics'
import { fromJSDate } from '@helpers/time'
import { globalStyles } from '../../constants/Styles'
import { TimestampView } from '../components/TimestampView'

function AdminSalesScreen() {
  const farm = useSelector(adminFarmSelector)

  const [totalSales, setTotalSales] = useState<TotalSales | null>(null)
  const [categorySales, setCategorySales] = useState<CategorySales[] | null>(null)
  /** useCache determines whether the sales report run on cache table or latest table. */
  const [useCache, setUseCache] = useState(true)
  const [forceRefresh, setForceRefresh] = useState(false)
  const [date, setDate] = useState<{ label: DateRangeNames; value: DateValue; pastLabel?: string }>({
    label: DateRangeNames.This_Year,
    value: getYearToDate(),
    pastLabel: 'last year',
  })

  const styles = useStyles()

  useFocusFx(
    () => {
      setTotalSales(null)
      setCategorySales(null)
      const selectedDate = date
      fetchTotalSales(selectedDate.value, farm.id, farm.timezone, useCache).then((res) => {
        if (date.label === selectedDate.label) setTotalSales(res) // Ensures that the date hasn't been changed in the meantime
      })

      fetchCategorySales(date.value, farm.id, farm.timezone, useCache).then((res) => {
        if (date.label === selectedDate.label) setCategorySales(res) // Ensures that the date hasn't been changed in the meantime
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps -- forceRefresh is used to refetch data when the user presses the refresh button every time, so it should be in the dependency array
    [date, farm.id, farm.timezone, useCache, forceRefresh],
    {
      noRefocus: true,
    },
  )

  const onRefreshPressed = useCallback(() => {
    // When refresh button is clicked, the data should be fetched from the latest table always not cache table.
    if (useCache) setUseCache(false)
    setForceRefresh((prev) => !prev)
  }, [useCache])
  return (
    <AdminView>
      <View style={styles.wrapper}>
        <View style={globalStyles.flexRowCenter}>
          <HeaderText size={30}>Sales Report</HeaderText>
          <Tooltip id={ToolTips.SALES_REPORT} size={15} />
        </View>
        <TimestampView isUsingCache={useCache} onRefreshPressed={onRefreshPressed} />
        <View style={styles.buttonsWrapper}>
          <View style={styles.btnCont}>
            <Export
              title="Export to CSV (Sorted)"
              onPress={() => {
                openSalesCSV(
                  farm.id,
                  {
                    startDate: fromJSDate(date.value.startDate, farm.timezone).startOf('day').toISO(),
                    endDate: fromJSDate(date.value.endDate, farm.timezone).endOf('day').toISO(),
                  },
                  'sorted',
                )
              }}
            />
          </View>
          <View style={styles.btnCont}>
            <Export
              title="Export to CSV (Unsorted)"
              onPress={() => {
                openSalesCSV(
                  farm.id,
                  {
                    startDate: fromJSDate(date.value.startDate, farm.timezone).startOf('day').toISO(),
                    endDate: fromJSDate(date.value.endDate, farm.timezone).endOf('day').toISO(),
                  },
                  'unsorted',
                )
              }}
            />
          </View>
        </View>
        <DatePicker
          date={date}
          timezone={farm.timezone}
          setDate={(name, date, pastLabel) => setDate({ label: name, value: date, pastLabel })}
        />

        <View style={styles.infoWrapper}>
          {/* Disabled for now
          <Overview data={totalSales} /> */}
          <SalesTable total={totalSales} data={categorySales} dateFilter={date.value} useCache={useCache} />
        </View>
      </View>
    </AdminView>
  )
}

const useStyles = CreateResponsiveStyle(
  {
    wrapper: {
      padding: 30,
    },
    buttonsWrapper: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 15,
      flexWrap: 'wrap',
    },
    infoWrapper: {
      marginVertical: 20,
    },
    btnCont: {
      marginBottom: 10,
      marginRight: 10,
    },
  },
  {
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      wrapper: {
        padding: 10,
      },
    },
  },
)

export default withAdminAuth(AdminSalesScreen, Permission.Accounting)
